<template>
  <v-card>
    <v-card-title class="justify-center">
      <!--
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      
      <v-pagination
        v-model="page"
        :length="pages"
      ></v-pagination>
      -->
      
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
      hide-default-footer
    ></v-data-table>

    <v-pagination
        v-model="page"
        :length="pages"
        v-on:input="fetchData()"
      ></v-pagination>

  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      url : process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        { text: "order", align: "start", value: "order" },
        { text: "date", value: "date" },
        { text: "card holder", value: "cardholder" },
        { text: "payment type", value: "payment_type" },
        { text: "card no.", value: "card_no" },
        { text: "card exp.", value: "card_exp" },
        { text: "policy", value: "policy" },
        { text: "main insured", value: "main_insured" },
        { text: "plan", value: "plan" },
        { text: "premium amount", value: "premium_amount" },
        { text: "fee amount", value: "fee_amount" },
        { text: "total amount", value: "total_amount" },
        { text: "transaction currency", value: "transaction_currency" },
        { text: "status", value: "status" },
        { text: "comments", value: "comments" },
      ],
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%"
        }
      ]
    };
  },
  methods: {
    fetchData() {
      console.log('llamando api')
      //axios.get("data/datos.json").then(response => {
      axios.get(this.url+'/cobros-bmi/paginado?page='+this.page+'&limit=10').then(response => {
        this.datos = response.data?.items || [];
        this.pages = response.data?.meta?.totalPages || 0;
      });
    },

  },
  created() {
    this.fetchData();
  }
};
</script>
